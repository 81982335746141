// https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/
.spinner {
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: auto;
  animation: 0.25s ease-in normal appear;

  span {
    position: absolute;
    width: 300px;
    height: 300px;
    padding-top: 65px;
    font-size: 100px;
    text-align: center;
    vertical-align: middle;
    animation: 2.5s ease-in-out infinite alternate pulse-size;
  }

  text {
  }
  svg.spinner {
    animation: 1s linear infinite spinner-svg-animation;
    max-width: 300px;
    background: none;

    // Circle styles.

    circle {
      animation: 2s ease-in-out infinite both spinner-circle-animation;
      display: block;
      fill: transparent;
      stroke: url(#myGradient);
      stroke-linecap: round;
      stroke-dashoffset: 50;
      stroke-dasharray: 15 100;
      stroke-width: 8px;
      transform-origin: 50% 50%;
    }
  }
}
// SVG animation.
@keyframes spinner-svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
// Circle animation.
@keyframes spinner-circle-animation {
  0% {
    stroke-dashoffset: 50;
    transform: rotate(0);
  }

  100% {
    stroke-dashoffset: 165;
    transform: rotate(360deg);
  }
}

@keyframes pulse-size {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
