@import "node_modules/bootstrap/scss/bootstrap";

svg {
  cursor: default;
  user-select: none;
  margin: 10px;
  display: block;
  pointer-events: all;
  background: white;
  shape-rendering: geometricPrecision;
  .hover {
    &,
    * {
      pointer-events: none;
    }
  }
}

.treadmill-icon {
  background: linear-gradient(45deg, #eee, #fff, #eee);
  background-size: 300% 300%;
  width: 100%;
  letter-spacing: -1em;
  padding-right: 1em;
  text-align: center;
  font-size: 100px;
  line-height: 300px;
}

.map-tabs {
  flex-wrap: nowrap;
  .nav-link {
    cursor: pointer;
  }
}

.placeholder {
  color: transparent !important;
  background: linear-gradient(45deg, #eee, #fff, #eee);
  background-size: 300% 300%;
  animation: 1s linear infinite alternate slide-gradient;
}

div.resize-container {
  overflow: hidden;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  &.resizing {
    background: linear-gradient(45deg, #eee, #fff, #eee);
    background-size: 300% 300%;
    animation: 1s linear infinite alternate slide-gradient;
    > svg {
      opacity: 0;
    }
  }

  > svg {
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
    width: 100%;
    height: 100%;
    transition: opacity 150ms;
    transition-delay: 10ms;
    margin: 0;
  }
}

.color-legend {
  text {
    font-size: 10px;
  }
}

nav.navbar {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: white !important;
}

nav.navbar.bg-light:not(*:root) {
  background-color: rgba(255, 255, 255, 0.65) !important;
  backdrop-filter: blur(10px);
}

header.analysis-nav {
  overflow: hidden;

  .navbar-brand {
    overflow: hidden;
    max-width: calc(100% - 75px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .collapse.show {
    position: relative;
  }

  + main {
    min-width: 0;
    min-height: 0;
    max-height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .row {
      flex: 1 1 auto;
      margin: 0;
      .col {
        flex-direction: column;
        flex: 1 1 auto;
      }
    }
  }
}

.effort-density {
  display: flex;
  height: 50px;
}

header + main {
  padding-top: 4.5rem;
}

* {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

svg.spinner {
  border: none;
  animation: anim 1000ms linear infinite;
  transform-origin: 75px, 90px;
}

html {
  height: 100%;
}

body,
#app {
  flex-direction: column;
  display: flex;
  height: 100%;
  max-height: 100%;
  background: #fafafa;
}

.jumbotron {
  height: 100vh;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.strava-auth {
  padding: 0;
  img {
    width: 250px;
  }
}

svg {
  .chart-title {
    font-size: 16px;
  }
  .x-axis,
  .y-axis {
    .axis-label {
      font-size: 14px;
    }
    .axis-value {
      font-size: 10px;
    }
  }
}

@keyframes slide-gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.alignment-baseline-bottom {
  alignment-baseline: bottom;
}

p.activity-description {
  max-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}
